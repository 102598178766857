exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-jury-selection-apps-js": () => import("./../../../src/pages/blog/jury-selection-apps.js" /* webpackChunkName: "component---src-pages-blog-jury-selection-apps-js" */),
  "component---src-pages-blog-jury-selection-js": () => import("./../../../src/pages/blog/jury-selection.js" /* webpackChunkName: "component---src-pages-blog-jury-selection-js" */),
  "component---src-pages-blog-jury-selection-software-js": () => import("./../../../src/pages/blog/jury-selection-software.js" /* webpackChunkName: "component---src-pages-blog-jury-selection-software-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-about-us-js": () => import("./../../../src/pages/info/about-us.js" /* webpackChunkName: "component---src-pages-info-about-us-js" */),
  "component---src-pages-info-contact-js": () => import("./../../../src/pages/info/contact.js" /* webpackChunkName: "component---src-pages-info-contact-js" */),
  "component---src-pages-info-how-to-guide-js": () => import("./../../../src/pages/info/how-to-guide.js" /* webpackChunkName: "component---src-pages-info-how-to-guide-js" */),
  "component---src-pages-info-jurybox-faqs-js": () => import("./../../../src/pages/info/jurybox-faqs.js" /* webpackChunkName: "component---src-pages-info-jurybox-faqs-js" */),
  "component---src-pages-license-agreement-js": () => import("./../../../src/pages/license-agreement.js" /* webpackChunkName: "component---src-pages-license-agreement-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

