import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            light: '#6396dc',
            main: '#5480B1',
            dark: '#3f6588',
            contrastText: '#eee'
        },
        secondary: {
            light: "#919191",
            main: "#565656",
            dark: "#343434",
            contrastText: "#FFF"
        }
    }
});

export default theme;
